import { Injectable, Inject, EventEmitter } from '@angular/core';
import { RestService } from '../core/rest.service';
import { AppConfig } from '../../config/app.config';
import { map } from 'rxjs/operators';
@Injectable()
export class TaskService {

  constructor( private restService: RestService) { }

  public getTasks(page, allTasks = false) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/task', { page, allTasks }, null).pipe(map((res: any) => res));
  }
  public getPoolTasks(page) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/task/pooledTasks?eventName=iasgcon-2024', { page }, null).pipe(map((res: any) => res));
  }
  public getPosterTasks(page, allTasks = false) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/task/poster/pooledTasks', { page, allTasks }, null).pipe(map((res: any) => res));
  }
  public getTaskData(id) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/iasgcon/taskDetails?taskId=' + id, true).pipe(map((res: any) => res));
  }
  public getTaskDataWithToken(id,token) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/iasgcon/taskDetails?taskId=' + id +'&token=' + token, true).pipe(map((res: any) => res));
  }
  public getPendingTasksList(taskId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/workflow/panelReview/pendingTask/' + taskId , true).pipe(map((res: any) => res));
  }
  public getWorkflowComment(manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/iasgcon/workflow/comments/' + manuscriptId, true).pipe(map((res: any) => res));
  }
  public getTaskStatus(taskId,manuscriptId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/tasks/status/' + taskId +'/' +manuscriptId , true).pipe(map((res: any) => res));
  }
  public claimTask(taskId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/task/claim/' + taskId , null).pipe(map((res: any) => res));
  }
  public unClaimTask(taskId) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/abstract/task/unClaim/' + taskId , null).pipe(map((res: any) => res));
  }
}
