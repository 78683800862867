import {
  Injectable,
  Inject
} from '@angular/core';

import {
  RestService
} from '../core/rest.service';

import {
  AppConfig
} from '../../config/app.config';
import { map } from 'rxjs/operators';
@Injectable()
export class GroupService {

  constructor(
    private restService: RestService) {

    }
    public getGroups() {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group', true).pipe(map((res: any) => res));
    }
    public getReviewers() {
      return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/reviewer/GROUP_iasgcon_iasgcon-2024_Reviewer', true).pipe(map((res: any) => res));
    }
    public getGroupDetails(topicName) {
      return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/reviewer/' + topicName, true).pipe(map((res: any) => res));
    }
    public createTopic(topicCode, topicName) {
      return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/reviewer/GROUP_iasgcon_iasgcon-2024_Reviewer/' + topicCode + '/' + topicName +'?conferenceName=iasgcon&eventName=iasgcon-2024', true).pipe(map((res: any) => res));
    }
    public createSubtopic(topicName, data) {
      return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/reviewer/' + topicName , data , true).pipe(map((res: any) => res));
    }
    public addUserFromGroup(values) {
      const data = {
        userId : values.id
      };
      return this.restService.post(AppConfig.BASE_URL_V1 + '/abstract/reviewer/user/' + values.groupName + '/' + values.id, true).pipe(map((res: any) => res));
    }
    public getPeerReviewerMembers() {
      return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/reviewer/allPanelReviewers/GROUP_iasgcon_iasgcon-2024_Reviewer?eventName=iasgcon-2024', true).pipe(map((res: any) => res));
    }
    public deleteUser(groupShortName, userid) {
      return this.restService.delete(AppConfig.BASE_URL_V1 + '/abstract/reviewer/' + groupShortName + '/' + userid, true).pipe(map((res: any) => res));
    }
    public getAllGroups() {
      return this.restService.get(AppConfig.BASE_URL_V1 + '/abstract/reviewer/subTopics/GROUP_iasgcon_iasgcon-2024_Reviewer', true).pipe(map((res: any) => res));
    }
    public editGroupDetails(value) {
      const  data = {
        displayName: value.displayName
      };
      return this.restService.put(AppConfig.BASE_URL + '/editaware/group/' + value.shortName, data, true).pipe(map((res: any) => res));
    }
   
    public getUserGroups(id) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/user/' + id, true).pipe(map((res: any) => res));
    }

    public addUser(values) {
      const data = {
        userId : values.id
      };
      return this.restService.post(AppConfig.BASE_URL + '/editaware/group/user/' + values.groupName + '/' + values.id, true).pipe(map((res: any) => res));
    }
  
    public addSubGroup(parentGroupName, values) {
      return this.restService.post(AppConfig.BASE_URL + '/editaware/group/' + parentGroupName + '/' + values.shortName, true).pipe(map((res: any) => res));
    }
 
    public getInitialReviewersMembers(journalId) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/initialReviewers/' + journalId, true).pipe(map((res: any) => res));
    }
    public getcopyEditorMembers(journalId) {
      return this.restService.get(AppConfig.BASE_URL + '/editaware/group/copyEditor/' + journalId, true).pipe(map((res: any) => res));
    }
}
