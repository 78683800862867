import { Compiler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';
import { RestService } from '../core/rest.service';
@Injectable()
export class AuthService {
  constructor(
    private restService: RestService, private compiler: Compiler,
    private router: Router) {
  }
  public login(emailId, password) {
    const data = {
      userId: emailId,
      password
    };
    return this.restService
      .post(AppConfig.BASE_URL + '/editaware/user/authorization', data ,  false).
      pipe(map((res: any) => res));
  }
  public superAdminLogin(emailId, password) {
    const data = {
      userId: emailId,
      password
    };
    return this.restService
      .post(AppConfig.BASE_URL + '/editaware/user/authorization', data ,  false).
      pipe(map((res: any) => res));
  }

  public loginWithGoogle(emailId, password , register) {
    const data = {
      userId: emailId,
      password
    };
    return this.restService
      .post(AppConfig.BASE_URL + '/editaware/user/authorization/google?register=' + register, data , false).
      pipe(map((res: any) => res));
  }

  public otpAuthorization(data, command , register) {
    return this.restService
      .post(AppConfig.BASE_URL + '/editaware/user/authorization/otp/' + command + '?register=' + register, data , false).
      pipe(map((res: any) => res));
  }
  public logout() {
    return this.restService
      .delete(AppConfig.BASE_URL + '/editaware/user/authorization', null).
      pipe(map((res: any) => res));
  }
  public forceLogout() {
    this.compiler.clearCache();
    localStorage.clear();
    this.router.navigate(['/iasgcon2024']);
  }
}
